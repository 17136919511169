<template>
  <div class="handleLog">
    <el-row class="titleRow">
      <span class="line"></span>
      <span>第三方系统列表</span>
    </el-row>
    <el-row class="selectRow">
      <!-- <div class="selectCol">
        <span>风险分级</span>
        <el-select v-model="value" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>-->
      <div class="selectCol">
        <span>系统名称</span>
        <el-input v-model="platformName" placeholder="请输入内容"></el-input>
      </div>
      <el-button class="checkOutBtn" @click="handleSearch">筛选</el-button>
    </el-row>
    <el-row class="tableRow">
      <el-table border :data="tableData" stripe style="width: 100%">
        <el-table-column align="center" prop="id" label="系统编号" width="120"></el-table-column>
        <el-table-column align="center" prop="name" label="系统名称">
          <template slot-scope="scope">
            <span :title="scope.row.name" class="overflowDoit">{{scope.row.name}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="charge_name" label="主要负责人">
          <template slot-scope="scope">
            <span :title="scope.row.charge_name" class="overflowDoit">{{scope.row.charge_name}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="charge_mobile" label="主要负责人手机号">
          <template slot-scope="scope">
            <span :title="scope.row.charge_mobile" class="overflowDoit">{{scope.row.charge_mobile}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button @click="checkOut(scope.row.id)" class="tableBtn">查看企业库</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        v-if="tableData.length>0"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[3, 5, 10, 15, 20]"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalCount"
      ></el-pagination>
    </el-row>
    <!-- 风险管控详情dia开始 -->
    <!-- :title="`作业活动清单详情(${userName}-${dangerName})`" -->
    <el-dialog
      :title="`作业活动清单详情(${dangerName})`"
      class="riskDia"
      :visible.sync="dialogDetail"
      width="80%"
      :before-close="dialogDetailClose"
    >
      <el-row class="tableRow">
        <el-form ref="form" :model="formData" label-width="120px">
          <el-form-item label="作业活动">
            <el-input style="width:20%" disabled v-model="formData.danger_accident"></el-input>
          </el-form-item>
          <el-form-item label="作业区域">
            <el-input style="width:20%" disabled v-model="formData.danger_name"></el-input>
          </el-form-item>
          <el-form-item label="作业类别">
            <el-input style="width:20%" disabled v-model="formData.type"></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="desc">
            <el-input
              class="modifyInner"
              type="textarea"
              disabled
              v-model="formData.danger_remarks"
            ></el-input>
          </el-form-item>
        </el-form>
      </el-row>
    </el-dialog>
    <!-- 风险管控详情dia结束 -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      token: '',
      userName: '',
      // 企业信息
      infoVisibale: false,
      platformName: '',
      dialogDetail: false,
      dangerName: '',
      danName: '',
      formData: {},
      value: '',
      startTime: '',
      currentPage: 1,
      totalCount: 0,
      pageSize: 15,
      tableData: [],
    }
  },
  methods: {
    handleSearch() {
      this.currentPage = 1
      this.getOtherList()
    },
    async getOtherList() {
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('length', this.pageSize)
      param.append('page', this.currentPage)
      param.append('platform_name', this.platformName)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/platform/index',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.tableData = res.datas.list
      this.totalCount = parseInt(res.datas.count)
    },
    async checkOut(id) {
      this.$router.push({
        path: '/manage/otherCompanyList',
        query: {
          id: id,
        },
      })
    },
    dialogDetailClose() {
      this.dialogDetail = false
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getOtherList()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getOtherList()
    },
  },
  created: function () {
    this.token = localStorage.getItem('btlh_token') || ''
    this.userName = localStorage.getItem('btlh_nickname') || ''
    this.getOtherList()
  },
}
</script>
<style lang="less" scoped>
.handleLog {
  padding: 20px;
  background-color: #fff;
  // 标题行
  .titleRow {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;
    .line {
      display: block;
      width: 4px;
      height: 22px;
      margin-right: 10px;
      background-color: #333;
    }
  }
  // 企业选择行
  .selectRow {
    display: flex;
    font-size: 12px;
    margin-bottom: 15px;
    .el-date-editor.el-input,
    .el-input {
      width: 68%;
    }
    .selectCol {
      margin-right: 10px;
    }
    /deep/ .el-input__inner {
      font-size: 10px;
      height: 26px;
      width: 120px;
      border-radius: 0;
      margin-left: 10px;
      padding-left: 10px;
      padding-right: 10px;
    }
    /deep/ .el-input__icon {
      display: none !important;
    }
    .checkOutBtn {
      font-size: 12px;
      padding: 5px 20px;
      background-color: #447ed9;
      color: #fff;
    }
  }
  // 表格
  .tableRow {
    padding: 0 10px;
  }
  /deep/ .el-table {
    margin-top: 0;
    font-size: 12px;
    td {
      padding: 5px 0;
    }
    td,
    th.is-leaf {
      border-bottom: 1px solid #eee;
    }
    th {
      background-color: #f7f7f7;
      color: #222;
      font-weight: 700;
    }
    .el-table--striped .el-table__body tr.el-table__row--striped td {
      background-color: #f7f7f7;
    }
  }
  .el-table::before {
    display: none;
  }
  // 分页
  .el-pagination {
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
  }
}
.tableBtn {
  font-size: 12px;
  padding: 5px 10px;
  background-color: #447ed9;
  color: #fff;
}
/deep/ .el-dialog__header {
  .el-dialog__title {
    font-size: 14px;
  }
  // logo
  background: url(../../assets/images/smile.png) no-repeat;
  background-size: 20px;
  background-position: 35px 22px;
  padding-left: 65px;
}
/deep/ .el-dialog__body {
  display: flex;
  flex-wrap: wrap;
  padding-left: 60px;
  .el-button:nth-child(1) {
    margin-left: 10px;
  }
  .el-button:nth-child(odd) {
    width: 30%;
    margin-right: 6%;
  }
  .el-button:nth-child(even) {
    width: 50%;
  }
  .el-button {
    margin-bottom: 10px;
    text-align: center;
    font-size: 12px;
    padding: 10px 0px;
    background-color: #447ed9;
    color: #fff;
    font-size: 14px;
    font-weight: normal;
  }
}
.tableRow {
  padding: 0 10px;
  width: 100%;
}
/deep/ .el-table {
  margin-top: 0;
  font-size: 12px;
  border: 1px solid #eee;
  td {
    padding: 5px 0;
  }
  td,
  th.is-leaf {
    border-bottom: 0;
  }
  th {
    background-color: #f7f7f7;
    color: #222;
    font-weight: 700;
  }
  .el-table--striped .el-table__body tr.el-table__row--striped td {
    background-color: #f7f7f7;
  }
}
.el-table::before {
  display: none;
}
// 分页
.el-pagination {
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
}
.riskDia {
  .tableBtnRisk {
    width: 100px !important;
    padding: 5px 10px !important;
    margin: 0 !important;
  }
  /deep/ .el-dialog__body {
    padding: 10px 20px 30px;
  }
  /deep/ .el-dialog__header {
    .el-dialog__title {
      font-size: 16px;
    }
  }
}
.riskDia {
  /deep/ .el-form-item {
    margin-bottom: 10px;
  }
  /deep/ .el-form-item__label {
    color: #000;
  }
  /deep/ .el-input__inner {
    height: 30px;
    border-radius: 0;
  }
  /deep/ .modifyInner textarea {
    border-radius: 0 !important;
    width: 80%;
    height: 70px;
  }
}

/deep/ .el-input.is-disabled .el-input__inner {
  color: #333 !important;
  background-color: #fff !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/deep/ .el-textarea.is-disabled .el-textarea__inner {
  color: #333 !important;
  background-color: #fff !important;
  overflow: auto;
  text-overflow: ellipsis;
}

// 超出...的容器
.overflowDoit {
  width: 100%;
  white-space: nowrap; //不换行
  overflow: hidden; //超出隐藏
  text-overflow: ellipsis; //变成...
}
</style>