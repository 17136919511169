<template>
  <el-container class="container">
    <!-- 头部区域 -->
    <el-header>
      <div>
        <i
          @click="slideNav"
          :class="isHidden?'el-icon-s-fold':'el-icon-s-unfold'"
          style="font-size:30px; color:#fff; cursor:pointer"
        ></i>
        <!-- <span>河南邦泰合力管理咨询有限公司</span> -->
        <span style="margin-left:17%">{{manageName}}</span>
        <!-- <span class="userName">{{manageName}}</span> -->
        <div class="userInfo">
          <!-- <span class="keshi" @click="goMain">
            <i style="font-size:20px" class="el-icon-s-home"></i>返回首页
          </span>-->
          <span class="keshi" @click="goCansee">
            <i style="font-size:20px" class="el-icon-s-platform"></i> 可视化界面
          </span>
          <img src="../../assets/images/user.png" alt />
          <el-dropdown>
            <span class="el-dropdown-link">
              {{manageName}}
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item icon="el-icon-setting" @click.native="handleChangePsd">修改密码</el-dropdown-item>
              <el-dropdown-item icon="el-icon-s-home" @click.native="goMain">返回首页</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

          <img
            @click="logout"
            style="width:16px;margin-left:10px;cursor:pointer;"
            src="../../assets/images/exit.png"
            alt
          />
        </div>
      </div>
    </el-header>
    <el-container>
      <!-- 左侧边栏(布局模块) -->
      <el-aside :width="isHidden? '64px' :'250px'">
        <!-- <el-menu :default-openeds="['1', '3']"> -->
        <el-menu
          class="el-menu-vertical-demo"
          text-color="#333"
          active-text-color="skyblue"
          :collapse="isHidden"
          :collapse-transition="false"
          @open="handleOpen"
          @close="handleClose"
          :router="true"
          @select="handleMenuSelect"
        >
          <!-- 控制菜单伸展按钮 -->
          <!-- <el-menu-item index="/home/dataOverview"> -->
          <el-menu-item index="/system/mapInfo" style="margin-left:4px">
            <div class="menuContainer">
              <i class="el-icon-s-opportunity"></i>
              <span slot="title">地图信息</span>
            </div>
          </el-menu-item>

          <el-submenu index="1">
            <template slot="title">
              <div class="menuContainer" :class="firstIndex=='1'?'isFatherAcitve':''">
                <i class="el-icon-user"></i>
                <span slot="title">双重预防</span>
              </div>
            </template>
            <el-menu-item index="/manage/companyList">
              <div class="menuContainer">
                <i class="el-icon-s-home"></i>
                <span slot="title">企业列表</span>
              </div>
            </el-menu-item>
            <!-- <el-submenu index="1-1" class="modiftTwo">
              <template slot="title">
                <div class="menuContainer" :class="secondIndex=='1-1'?'isFatherAcitve':''">
                  <i class="el-icon-s-order"></i>
                  <span slot="title">企业管理</span>
                </div>
              </template>
            <el-menu-item-group>-->

            <!-- <el-menu-item index="/manage/handleLog">
                  <div class="menuContainer1">操作日志</div>
            </el-menu-item>-->
            <!-- <el-menu-item index="/home/dataOverview">
                  <div class="menuContainer1">数据概览</div>
            </el-menu-item>-->

            <!-- <el-menu-item index="/manage/companyList">
                  <div class="menuContainer1">企业列表</div>
                </el-menu-item>
                <el-menu-item index="/manage/others">
                  <div class="menuContainer1">第三方系统</div>
                </el-menu-item>
            <el-menu-item index="/manage/bigData">-->

            <!-- <div class="menuContainer1">大数据搜索</div> -->

            <!-- <div class="menuContainer1">邦泰企业列表</div>
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>-->
            <el-submenu index="1-2" class="modiftTwo">
              <template slot="title">
                <div class="menuContainer" :class="secondIndex=='1-2'?'isFatherAcitve':''">
                  <i class="el-icon-message-solid"></i>
                  <span slot="title">风险分级管控</span>
                </div>
              </template>
              <el-menu-item-group>
                <el-menu-item index="/danger/recognize">
                  <div class="menuContainer1">风险点辨识</div>
                </el-menu-item>
                <el-menu-item index="/danger/workList">
                  <div class="menuContainer1">作业活动清单</div>
                </el-menu-item>
                <el-menu-item index="/danger/workEvaluate">
                  <div class="menuContainer1">作业活动风险评价清单</div>
                </el-menu-item>
                <el-menu-item index="/danger/equipment">
                  <div class="menuContainer1">设备设施清单</div>
                </el-menu-item>
                <el-menu-item index="/danger/equipmentRisk">
                  <div class="menuContainer1">设备设施风险评价清单</div>
                </el-menu-item>
                <el-menu-item index="/danger/dangerCheck">
                  <div class="menuContainer1">隐患排查清单</div>
                </el-menu-item>
                <el-menu-item index="/danger/dangerPeople">
                  <div class="menuContainer1">隐患指派人管理</div>
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>

            <el-submenu index="1-3" class="modiftTwo">
              <template slot="title">
                <div class="menuContainer" :class="secondIndex=='1-3'?'isFatherAcitve':''">
                  <i class="el-icon-s-promotion"></i>
                  <span slot="title">安全巡查</span>
                </div>
              </template>
              <el-menu-item-group>
                <el-menu-item index="/examine/examineHistory">
                  <div class="menuContainer1">巡检记录</div>
                </el-menu-item>
                <el-menu-item index="/examine/examineNum">
                  <div class="menuContainer1">巡检班次任务</div>
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <el-submenu index="1-4" class="modiftTwo">
              <template slot="title">
                <div class="menuContainer" :class="secondIndex=='1-4'?'isFatherAcitve':''">
                  <i class="el-icon-postcard"></i>
                  <span slot="title">教育培训</span>
                </div>
              </template>
              <el-menu-item-group>
                <el-menu-item index="/preventEducation/test">
                  <div class="menuContainer1">考试管理</div>
                </el-menu-item>
                <el-menu-item index="/preventEducation/train">
                  <div class="menuContainer1">会议记录</div>
                </el-menu-item>
                <el-menu-item index="/preventEducation/study">
                  <div class="menuContainer1">学习文库</div>
                </el-menu-item>
                <el-menu-item index="/preventEducation/video">
                  <div class="menuContainer1">学习视频</div>
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <!-- <el-submenu index="1-4" class="modiftTwo">
              <template slot="title">
                <div class="menuContainer" :class="secondIndex=='1-4'?'isFatherAcitve':''">
                  <i class="el-icon-s-grid"></i>
                  <span slot="title">系统管理</span>
                </div>
              </template>
              <el-menu-item-group>
                <el-menu-item index="/system/carousel">
                  <div class="menuContainer1">首页设置</div>
                </el-menu-item>
                <el-menu-item index="/system/message">
                  <div class="menuContainer1">通知公告</div>
                </el-menu-item>
                <el-menu-item index="/system/areaGridding">
                  <div class="menuContainer1">区域网格</div>
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>-->
          </el-submenu>

          <el-submenu index="6">
            <template slot="title">
              <div class="menuContainer" :class="firstIndex=='6'?'isFatherAcitve':''">
                <i class="el-icon-s-grid"></i>
                <span slot="title">系统管理</span>
              </div>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/system/carousel">
                <div
                  class="menuContainer"
                  :class="secondIndex=='/system/carousel'?'isFatherAcitve':''"
                >
                  <i class="el-icon-document-copy"></i>
                  <!-- <span slot="title">课程管理</span> -->
                  <span slot="title">首页设置</span>
                </div>
              </el-menu-item>
              <el-menu-item index="/system/indexLink">
                <div
                  class="menuContainer"
                  :class="secondIndex=='/system/indexLink'?'isFatherAcitve':''"
                >
                  <i class="el-icon-link"></i>
                  <!-- <span slot="title">课程管理</span> -->
                  <span slot="title">首页链接</span>
                </div>
              </el-menu-item>
              <el-menu-item index="/system/message">
                <div
                  class="menuContainer"
                  :class="secondIndex=='/system/message'?'isFatherAcitve':''"
                >
                  <i class="el-icon-folder-remove"></i>
                  <span slot="title">通知公告</span>
                </div>
              </el-menu-item>
              <el-menu-item index="/system/areaGridding">
                <div
                  class="menuContainer"
                  :class="secondIndex=='/system/areaGridding'?'isFatherAcitve':''"
                >
                  <i class="el-icon-edit"></i>
                  <span slot="title">区域网格</span>
                </div>
              </el-menu-item>
              <!-- <el-menu-item index="/system/mapInfo">
                <div
                  class="menuContainer"
                  :class="secondIndex=='/system/mapInfo'?'isFatherAcitve':''"
                >
                  <i class="el-icon-s-opportunity"></i>
                  <span slot="title">地图信息</span>
                </div>
              </el-menu-item>-->
              <el-menu-item index="/manage/others">
                <div
                  class="menuContainer"
                  :class="secondIndex=='/manage/others'?'isFatherAcitve':''"
                >
                  <i class="el-icon-s-data"></i>
                  <span slot="title">第三方系统</span>
                </div>
              </el-menu-item>
              <el-menu-item index="/system/bindCompany" v-if="is_root=='1'">
                <div
                  class="menuContainer"
                  :class="secondIndex=='/system/bindCompany'?'isFatherAcitve':''"
                >
                  <i class="el-icon-s-data"></i>
                  <span slot="title">企业政府端绑定</span>
                </div>
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>

          <el-submenu index="2">
            <template slot="title">
              <div class="menuContainer" :class="firstIndex=='2'?'isFatherAcitve':''">
                <i class="el-icon-menu"></i>
                <span slot="title">教育培训</span>
              </div>
            </template>

            <el-menu-item-group>
              <el-menu-item index="/education/classManagement">
                <div
                  class="menuContainer"
                  :class="secondIndex=='/education/classManagement'?'isFatherAcitve':''"
                >
                  <i class="el-icon-document-copy"></i>
                  <!-- <span slot="title">课程管理</span> -->
                  <span slot="title">课件管理</span>
                </div>
              </el-menu-item>
              <el-menu-item index="/education/testManagement">
                <div
                  class="menuContainer"
                  :class="secondIndex=='/education/testManagement'?'isFatherAcitve':''"
                >
                  <i class="el-icon-reading"></i>
                  <span slot="title">考试管理</span>
                </div>
              </el-menu-item>
              <el-menu-item index="/education/makeTest">
                <div
                  class="menuContainer"
                  :class="secondIndex=='/education/makeTest'?'isFatherAcitve':''"
                >
                  <i class="el-icon-folder-remove"></i>
                  <span slot="title">会议记录</span>
                </div>
              </el-menu-item>
              <el-menu-item index="/education/makeTestRule">
                <div
                  class="menuContainer"
                  :class="secondIndex=='/education/makeTestRule'?'isFatherAcitve':''"
                >
                  <i class="el-icon-edit"></i>
                  <span slot="title">学习文库</span>
                </div>
              </el-menu-item>
              <el-menu-item index="/education/testHistory">
                <div
                  class="menuContainer"
                  :class="secondIndex=='/education/testHistory'?'isFatherAcitve':''"
                >
                  <i class="el-icon-edit-outline"></i>
                  <span slot="title">学习视频</span>
                </div>
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="3">
            <template slot="title">
              <div class="menuContainer" :class="firstIndex=='3'?'isFatherAcitve':''">
                <i class="el-icon-location"></i>
                <span slot="title">执法工作</span>
              </div>
            </template>
            <el-menu-item-group>
              <!-- <el-menu-item index="/enforce/polling">
                <div
                  class="menuContainer"
                  :class="secondIndex=='/enforce/polling'?'isFatherAcitve':''"
                >
                  <i class="el-icon-document-remove"></i>
                  <span slot="title">企业巡检率</span>
                </div>
              </el-menu-item>-->
              <el-menu-item index="/enforce/enforcePlan">
                <div
                  class="menuContainer"
                  :class="secondIndex=='/enforce/enforcePlan'?'isFatherAcitve':''"
                >
                  <i class="el-icon-document-remove"></i>
                  <span slot="title">执法计划</span>
                </div>
              </el-menu-item>
              <el-menu-item index="/enforce/enforceHistory">
                <div
                  class="menuContainer"
                  :class="secondIndex=='/enforce/enforceHistory'?'isFatherAcitve':''"
                >
                  <i class="el-icon-document"></i>
                  <span slot="title">执法记录</span>
                </div>
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <!-- <el-submenu index="4">
            <template slot="title">
              <div class="menuContainer" :class="firstIndex=='4'?'isFatherAcitve':''">
                <i class="el-icon-s-flag"></i>
                <span slot="title">应急管理</span>
              </div>
            </template>
            <el-menu-item-group>neirong</el-menu-item-group>
          </el-submenu>-->
          <el-submenu index="5">
            <template slot="title">
              <div class="menuContainer" :class="firstIndex=='5'?'isFatherAcitve':''">
                <i class="el-icon-message-solid"></i>
                <span slot="title">监控预警</span>
              </div>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/monitoring/jar">
                <div class="menuContainer" :class="secondIndex=='5-1'?'isFatherAcitve':''">
                  <i class="el-icon-s-release"></i>
                  <span slot="title">储罐实时监控</span>
                </div>
              </el-menu-item>
              <el-menu-item index="/monitoring/gas">
                <div
                  class="menuContainer"
                  :class="secondIndex=='/monitoring/gas'?'isFatherAcitve':''"
                >
                  <i class="el-icon-s-opportunity"></i>
                  <span slot="title">气体浓度实时监控</span>
                </div>
              </el-menu-item>
              <el-menu-item index="/monitoring/video">
                <div
                  class="menuContainer"
                  :class="secondIndex=='/monitoring/video'?'isFatherAcitve':''"
                >
                  <i class="el-icon-s-marketing"></i>
                  <span slot="title">视频实时监控</span>
                </div>
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <!-- <el-menu-item index="/manage/companyList">
            <i class="el-icon-s-home"></i>
            <span slot="title">企业列表</span>
          </el-menu-item>
          <el-submenu index="2">
            <template slot="title">
              <i class="el-icon-location"></i>
              <span slot="title">企业管理</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/manage/handleLog">操作日志</el-menu-item>
              <el-menu-item index="/home/dataOverview">数据概览</el-menu-item>
              <el-menu-item index="/manage/others">第三方系统</el-menu-item>
              <el-menu-item index="/manage/bigData">大数据搜索</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="3">
            <template slot="title">
              <i class="el-icon-menu"></i>
              <span slot="title">风险分级管控</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/danger/recognize">风险点辨识</el-menu-item>
              <el-menu-item index="/danger/workList">作业活动清单</el-menu-item>
              <el-menu-item index="/danger/workEvaluate">作业活动评价清单</el-menu-item>
              <el-menu-item index="/danger/equipment">设备设施清单</el-menu-item>
              <el-menu-item index="/danger/equipmentRisk">设备设施风险评价清单</el-menu-item>
              <el-menu-item index="/danger/dangerCheck">隐患排查清单</el-menu-item>
              <el-menu-item index="/danger/dangerPeople">隐患指派人管理</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="4">
            <template slot="title">
              <i class="el-icon-s-flag"></i>
              <span slot="title">安全巡查</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/examine/examineNum">巡检班次任务</el-menu-item>
              <el-menu-item index="/examine/examineHistory">巡检记录</el-menu-item>
            </el-menu-item-group>
          </el-submenu>-->
        </el-menu>
      </el-aside>
      <!-- 主体区域 -->
      <el-main>
        <router-view v-if="isRouterAlive" />
      </el-main>
    </el-container>
    <!-- 隐藏预警信息 -->
    <!-- 隐藏预警信息 -->
    <!-- 隐藏预警信息 -->
    <!-- 隐藏预警信息 -->
    <!-- <el-dialog
      :title="`预警信息`"
      class="riskDia alertD"
      :visible.sync="diaLog"
      width="30%"
      :before-close="diaLogClose"
    >
      <el-row class="tableRowModify">
        <p class="alertP">
          待整改:
          <span>{{dataDetails.zgnum}}</span>
          <el-button @click="goEm(1)" class="checkB">查看</el-button>
        </p>
        <p class="alertP">
          待复查:
          <span>{{dataDetails.fcnum}}</span>
          <el-button @click="goEm(2)" class="checkB">查看</el-button>
        </p>
      </el-row>
    </el-dialog>-->

    <el-dialog
      :title="`修改密码`"
      class="riskDia"
      :visible.sync="dialogVisible"
      width="40%"
      :before-close="dialogDetailClose"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="140px"
        class="demo-ruleForm"
      >
        <el-form-item prop="name" label="请输入真实姓名" class="loginUserName">
          <el-input style="width:80%" placeholder="请输入用户名" v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item prop="newPwd" label="请输入新密码" class="loginPsd">
          <el-input
            style="width:80%"
            type="password"
            placeholder="请输入用密码"
            v-model="ruleForm.newPwd"
          ></el-input>
        </el-form-item>
        <el-form-item prop="confirmPwd" label="请确认新密码" class="loginPsd">
          <el-input
            style="width:80%"
            type="password"
            placeholder="请确认密码"
            v-model="ruleForm.confirmPwd"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" style="margin-left:50px" @click="changeSubmit('ruleForm')">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>
<script>
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (this.ruleForm.confirmPwd !== '') {
          this.$refs.ruleForm.validateField('confirmPwd')
        }
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.ruleForm.newPwd) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      is_root: '',
      isRouterAlive: true,
      routePath: '',
      diaLog: false,
      dataDetails: {},
      sendId: '',
      firstIndex: '',
      secondIndex: '',
      token: '',
      dialogVisible: false,
      checkedNav: '',
      isHidden: false,
      sideNavData: [],
      manageName: '',
      ruleForm: {
        name: '',
        newPwd: '',
        confirmPwd: '',
      },
      rules: {
        name: [
          { required: true, message: '请输入登录账号', trigger: 'blur' },
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' },
        ],
        newPwd: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { validator: validatePass, trigger: 'blur' },
        ],
        confirmPwd: [
          { required: true, message: '请再次输入新密码', trigger: 'blur' },
          { validator: validatePass2, trigger: 'blur', required: true },
        ],
      },
    }
  },
  provide() {
    return {
      reload: this.reload,
    }
  },
  methods: {
    reload() {
      this.isRouterAlive = false //先关闭，
      this.$nextTick(function () {
        this.isRouterAlive = true //再打开
      })
    },
    goEm(type) {
      this.diaLog = false
      this.$router.push({
        path: '/examine/examineHistory',
        query: {
          inspection_status: type,
        },
      })
    },
    handleCheck(row) {
      this.dataName = row.content
    },
    async getDialog() {
      // /index/needToDo
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('type', 1)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/index/needToDo',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      console.log('dialog', res)
      this.dataDetails = res.datas
      this.diaLog = true
    },
    // 返回首页
    goMain() {
      this.$router.push('/welcome')
    },
    goCansee() {
      window.open(
        `http://anquan.sxpz.vip/AppApi/Gov/index.html?id=${this.sendId}`
      )
    },
    changeSubmit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (!valid) return this.$message.error('填写信息有误')
        let param = new URLSearchParams()
        param.append('access_token', this.token)
        param.append('nickname	', this.ruleForm.name)
        param.append('password', this.ruleForm.newPwd)
        const { data: res } = await this.$axios({
          method: 'post',
          url: '/api/index/changeUser',
          data: param,
        })
        console.log(res) / examine / examineHistory
        // 如果向服务器提交失败
        if (res.code != 200) return this.$message.error(res.datas.msg)
        // 如果成功
        this.$message.success('修改成功')
        this.dialogVisible = false
      })
    },
    // 修改密码
    handleChangePsd() {
      this.dialogVisible = true
    },
    // 侧边栏点击
    handleMenuSelect(index, indexPath) {
      console.log('index', index)
      console.log('indexPath', indexPath)
      // if (index == '/manage/companyList') {
      //   this.secondIndex = ''
      //   this.firstIndex = '1'
      // }
      if (index == '/system/mapInfo') {
        this.secondIndex = ''
        this.firstIndex = ''
      }
      if (
        index == '/manage/handleLog' ||
        index == '/home/dataOverview' ||
        index == '/manage/handleLog' ||
        index == '/manage/bigData' ||
        index == '/manage/companyList'
      ) {
        this.secondIndex = '1-1'
        this.firstIndex = '1'
      } else if (
        index == '/danger/recognize' ||
        index == '/danger/workList' ||
        index == '/danger/workEvaluate' ||
        index == '/danger/equipment' ||
        index == '/danger/dangerCheck' ||
        index == '/danger/dangerPeople' ||
        index == '/danger/equipmentRisk'
      ) {
        // 双重预防-风险分级管控
        this.secondIndex = '1-2'
        this.firstIndex = '1'
      } else if (
        index == '/examine/examineNum' ||
        index == '/examine/examineHistory'
      ) {
        // 双重预防-安全巡查
        this.secondIndex = '1-3'
        this.firstIndex = '1'
      } else if (
        index == '/preventEducation/test' ||
        index == '/preventEducation/train' ||
        index == '/preventEducation/study' ||
        index == '/preventEducation/video'
      ) {
        // 双重预防-教育培训
        this.secondIndex = '1-4'
        this.firstIndex = '1'
      } else if (
        // 双重预防-系统管理
        index == '/system/areaGridding' ||
        index == '/system/message' ||
        index == '/system/indexLink' ||
        index == '/manage/others' ||
        index == '/system/bindCompany' ||
        index == '/system/carousel'
      ) {
        this.firstIndex = '6'
        this.secondIndex = ''
      } else if (
        //教育培训
        index == '/education/classManagement' ||
        index == '/education/testManagement' ||
        index == '/education/makeTest' ||
        index == '/education/testHistory' ||
        index == '/education/makeTestRule'
      ) {
        this.firstIndex = '2'
        this.secondIndex = ''
      } else if (
        //执法工作
        index == '/enforce/polling' ||
        index == '/enforce/enforceHistory' ||
        index == '/enforce/enforcePlan'
      ) {
        // this.secondIndex = '1-4'
        this.secondIndex = ''
        this.firstIndex = '3'
      } else if (
        //监控预警
        index == '/monitoring/jar' ||
        index == '/monitoring/gas' ||
        index == '/monitoring/video'
      ) {
        // this.secondIndex = '1-4'
        this.secondIndex = ''
        this.firstIndex = '5'
      }
      if (index == '3-1' || index == '2-2') {
        return false
      }
      return
      console.log('$route.path', this.$route.path)
      console.log('routePath', this.routePath)
      if (this.$route.path != this.path) {
        // alert(this.$route.path )
        if (index == '3-1' || index == '2-2') {
          // this.$router.push(this.$route.path)
          indexPath = '3-1'
          // alert(this.$route.path)
        }
      }
    },
    handleOpen(index) {
      console.log('zhankai', index)
      if (index.length > 1) {
        console.log('展开2级')
        this.secondIndex = index
      } else {
        console.log('展开1')
        this.firstIndex = index
      }
    },

    handleClose(index) {
      console.log('关闭index', index)
      if (index.length > 1) {
        this.secondIndex = ''
      } else {
        this.firstIndex = ''
      }
    },

    // 记录做侧边栏哪个选项被点击了
    changeNaveState(checkedNavIndex) {
      window.sessionStorage.setItem('checkedNav', checkedNavIndex)
      this.checkedNav = checkedNavIndex
    },
    // 点击展开侧边导航栏
    slideNav() {
      this.isHidden = !this.isHidden
    },
    // 登出功能
    logout() {
      window.localStorage.clear()
      this.$router.push('/login')
    },
    dialogDetailClose() {
      this.dialogVisible = false
    },
    diaLogClose() {
      this.diaLog = false
    },
  },
  async created() {
    this.token = localStorage.getItem('btlh_token') || ''
    this.is_root = localStorage.getItem('btlh_is_root') || ''
    console.log('路由路由路由路由路由路由路由', this.$route.path)
    if (this.$route.path == '/manage/first') {
      this.getDialog()
    }
    if (this.$route.path == '/home/fastCompany') {
      if (this.$route.query.type) {
        let param = new URLSearchParams()
        param.append('type', this.$route.query.type)
        const { data: res } = await this.$axios({
          method: 'post',
          url: '/api/login/fastLogin',
          data: param,
        })
        if (res.code != 200) return this.$message.error(res.datas.msg)
        console.log(res)
        // 如果成功
        window.localStorage.setItem('btlh_nickname', res.datas.nickname)
        window.localStorage.setItem('btlh_companyid', res.datas.company_id)
        window.localStorage.setItem('btlh_id', res.datas.id)
        window.localStorage.setItem('btlh_token', res.datas.access_token)
        this.manageName = res.datas.nickname
        this.token = res.datas.access_token
      }
    } else {
      this.token = localStorage.getItem('btlh_token') || ''
      this.manageName = window.localStorage.getItem('btlh_nickname') || ''
      if (this.manageName) {
        document.title = this.manageName + '-邦泰云安全'
      }
      this.sendId = window.localStorage.getItem('btlh_id') || ''
      console.log('sendId', this.sendId)
    }
  },
}
</script>
<style lang="less" scoped>
.el-main {
  height: 92vh;
  overflow: auto;
  background-color: #f4f4f4;
  padding: 20px;
  padding-bottom: 0;
}
.el-container {
  height: 100%;
}

.el-header {
  background-color: #527dd3;
  height: 8vh !important;
}

/deep/ .el-aside {
  background-color: #fff;
  height: 92vh;
  /deep/ .el-menu-item-group__title {
    display: none;
  }
}
.el-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  > div {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    span {
      // width: 100%;
      text-align: center;
      margin-left: 20px;
      font-size: 20px;
      color: #fff;
    }
  }
}
.iconfont {
  margin-right: 15px;
}
.slide {
  text-align: center;
  font-size: 20px;
  color: #fff;
}
// 修改调ul的边框
.el-menu {
  border-right: 0;
}
.userInfo {
  display: flex;
  align-items: center;
  img {
    width: 18px;
  }
}
/deep/.el-dropdown {
  display: flex;
  align-items: center;
}
/deep/ .el-dropdown-link {
  margin-left: 0 !important;
  font-size: 14px !important;
  margin-left: 10px !important;
}
/deep/ .el-dropdown-link .userName {
}
.demo-ruleForm {
  padding-left: 60px;
}
/deep/ .el-dialog__footer {
  text-align: center;
}
.keshi {
  cursor: pointer;
  font-size: 14px !important;
  display: flex;
  align-items: center;
  margin-right: 30px;
}

// *导航栏样式
/deep/ .el-submenu__title:hover {
  // background-color: #eee;
  // border-radius: 30px;
  // width: 90%;
  font-weight: 700 !important;
  background-color: rgba(0, 0, 0, 0);
}
/deep/ .el-submenu__title {
  cursor: pointer;
  height: 30px;
  line-height: 30px;
  // padding-left: 10px !important;
  transition: none;
  width: 90%;
  padding: 0 !important;
  margin: 0 auto;
}
.menuContainer {
  box-sizing: border-box;
  width: 100%;
  padding: 0 20px;
}
/deep/ .el-menu-item {
  cursor: pointer;
  height: 30px;
  line-height: 30px;
  transition: none;
  width: 90%;
  padding: 0 !important;
  padding-left: 10px !important;
  margin: 0 auto;
}
/deep/ .el-menu-item:hover {
  // border-radius: 30px;
  // color: #409eff;
  font-weight: 700 !important;
  // border-radius: 30px;
  // background-color: #31a0ed;
  background-color: rgba(0, 0, 0, 0);
  // width: 70% !important;
  // margin-left: 30px;
  color: #333 !important;
}
/deep/ .el-menu-item.is-active {
  background-color: rgba(0, 0, 0, 0);
}
/deep/ .el-menu-item.is-active > div {
  color: #409eff;
  border-radius: 30px;
  font-weight: 700 !important;
  // background-color: #31a0ed;
  background-color: #31a0ed;
  // width: 70%;
  // margin-left: 30px;
  color: #fff !important;
}

.modiftTwo {
  padding-left: 10px !important;
}
.isFatherAcitve {
  font-weight: 700 !important;
  color: #409eff;
  border-radius: 30px;
  // background-color: #31a0ed;
  background-color: #f3f3f3;
  // width: 70%;
  // margin-left: 30px;
  color: #262626 !important;
}
.menuContainer1 {
  box-sizing: border-box;
  width: 82%;
  padding: 0 20px;
  margin: 0 auto;
}
/deep/ .el-aside i {
  color: #0073c5;
}
// 导航栏样式
.alertD {
  /deep/ .el-dialog__body {
    padding-top: 10px;
  }
}
.alertP {
  margin-left: 30px;
}
.checkB {
  padding: 8px 15px;
  background-color: #447ed9;
  border: 0;
  color: #fff;
  float: right;
  margin-right: 30px;
}
</style>
