<template>
  <div>
    <el-row>
      <!-- 日历  -->
      <el-col class="dateCol" :span="5">
        <el-calendar v-model="valueDate"></el-calendar>
      </el-col>
      <!-- 任务查看 -->
      <el-col :span="18" class="workWaitCol">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane :label="`待指派(${waitSend})`" name="first">
            <div class="tableDataEmpty" v-if="workData.length==0">没有指派任务!</div>
            <el-table v-if="workData.length>0" :data="workData" stripe style="width: 100%">
              <el-table-column align="center" prop="add_time" label="巡查时间"></el-table-column>
              <el-table-column align="center" prop="company_name" label="企业名称" width="250"></el-table-column>
              <el-table-column align="center" prop="danger_name" label="风险点名称"></el-table-column>
              <el-table-column align="center" label="风险等级" width="70">
                <template slot-scope="scope">
                  <span v-if="scope.row.danger_level==1" style="color:blue">低风险</span>
                  <span v-if="scope.row.danger_level==2" style="color:#e7ea45">一般风险</span>
                  <span v-if="scope.row.danger_level==3" style="color:#f9972f">较大风险</span>
                  <span v-if="scope.row.danger_level==4" style="color:red">重大风险</span>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="inspection_name" label="班次名称"></el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane :label="`待整改(${waitChange})`" name="second">
            <div class="tableDataEmpty" v-if="workData.length==0">没有指派任务!</div>
            <el-table v-if="workData.length>0" :data="workData" stripe style="width: 100%">
              <el-table-column align="center" prop="add_time" label="巡查时间"></el-table-column>
              <el-table-column align="center" prop="company_name" label="企业名称" width="250"></el-table-column>
              <el-table-column align="center" prop="danger_name" label="风险点名称"></el-table-column>
              <el-table-column align="center" label="风险等级" width="70">
                <template slot-scope="scope">
                  <span v-if="scope.row.danger_level==1" style="color:blue">低风险</span>
                  <span v-if="scope.row.danger_level==2" style="color:#e7ea45">一般风险</span>
                  <span v-if="scope.row.danger_level==3" style="color:#f9972f">较大风险</span>
                  <span v-if="scope.row.danger_level==4" style="color:red">重大风险</span>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="inspection_name" label="班次名称"></el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane :label="`待复查(${waitLook})`" name="third">
            <div class="tableDataEmpty" v-if="workData.length==0">没有指派任务!</div>
            <el-table v-if="workData.length>0" :data="workData" stripe style="width: 100%">
              <el-table-column align="center" prop="add_time" label="巡查时间"></el-table-column>
              <el-table-column align="center" prop="company_name" label="企业名称" width="250"></el-table-column>
              <el-table-column align="center" prop="danger_name" label="风险点名称"></el-table-column>
              <el-table-column align="center" label="风险等级" width="70">
                <template slot-scope="scope">
                  <span v-if="scope.row.danger_level==1" style="color:blue">低风险</span>
                  <span v-if="scope.row.danger_level==2" style="color:#e7ea45">一般风险</span>
                  <span v-if="scope.row.danger_level==3" style="color:#f9972f">较大风险</span>
                  <span v-if="scope.row.danger_level==4" style="color:red">重大风险</span>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="inspection_name" label="班次名称"></el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
        <el-pagination
          v-if="workData.length>0"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          background
          :page-size="3"
          layout="total,  prev, pager, next, jumper"
          :total="totalCount"
        ></el-pagination>
      </el-col>
    </el-row>
    <el-row class="cilckRow">
      <div class="clickItem">
        <router-link :to="{path:'/manage/companyList'}">
          <img src="../../assets/images/logo01.png" />
          <span>企业信息总览</span>
        </router-link>
      </div>
      <!-- <div class="clickItem">
        <router-link :to="{path:'/home'}">
          <img src="../../assets/images/logo02.png" />
          <span>企业标杆</span>
        </router-link>
      </div>-->
      <div class="clickItem">
        <router-link :to="{path:'/examine/examineHistory'}">
          <img src="../../assets/images/logo03.png" />
          <span>巡检记录</span>
        </router-link>
      </div>
      <div class="clickItem">
        <router-link :to="{path:'/danger/equipment'}">
          <img src="../../assets/images/logo04.png" />
          <span>设备设施清单</span>
        </router-link>
      </div>
      <div class="clickItem">
        <router-link :to="{path:'/danger/workList'}">
          <img src="../../assets/images/logo05.png" />
          <span>作业活动清单</span>
        </router-link>
      </div>
      <div class="clickItem">
        <router-link :to="{path:'/danger/recognize'}">
          <img src="../../assets/images/logo06.png" />
          <span>风险辨识清单</span>
        </router-link>
      </div>
    </el-row>
    <el-row class="tableRow">
      <div>
        <span class="title">巡检记录</span>
        <el-button-group>
          <el-button :class="activeBtn==1?'acitveButton':''" @click="btnClick(1)" type="primary">今日</el-button>
          <el-button :class="activeBtn==2?'acitveButton':''" @click="btnClick(2)" type="primary">本周</el-button>
          <el-button :class="activeBtn==3?'acitveButton':''" @click="btnClick(3)" type="primary">本月</el-button>
        </el-button-group>
      </div>
      <div class="details">
        <span class="line"></span>
        <span>详细数据</span>
      </div>
      <div class="tableDataEmpty1" v-if="historyData.length==0">没有指派任务!</div>
      <el-table v-if="historyData.length>0" :data="historyData" stripe style="width: 100%">
        <el-table-column align="center" prop="add_time" label="巡查时间"></el-table-column>
        <el-table-column align="center" prop="company_name" label="企业名称"></el-table-column>
        <el-table-column align="center" prop="danger_name" label="风险点名称" width="300"></el-table-column>
        <el-table-column align="center" label="风险等级">
          <template slot-scope="scope">
            <span v-if="scope.row.danger_level==1" style="color:blue">低风险</span>
            <span v-if="scope.row.danger_level==2" style="color:#e7ea45">一般风险</span>
            <span v-if="scope.row.danger_level==3" style="color:#f9972f">较大风险</span>
            <span v-if="scope.row.danger_level==4" style="color:red">重大风险</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="inspection_name" label="班次名称"></el-table-column>
      </el-table>
      <el-pagination
        v-if="historyData.length>0"
        @current-change="handleCurrentChangeH"
        :current-page="currentPageHistory"
        :page-size="5"
        background
        layout="total, prev, pager, next, jumper"
        :total="totalCountHistory"
      ></el-pagination>
    </el-row>
  </div>
</template>
<script>
import qs from 'qs'
export default {
  data() {
    return {
      welcomeData: {
        type: 1,
        page: 1,
        timetype: 3,
      },
      // 待指派
      waitSend: 0,
      waitChange: 0,
      waitLook: 0,
      activeName: 'first',
      currentPage: 1,
      currentPageHistory: 1,
      totalCount: 0,
      totalCountHistory: 0,
      valueDate: new Date(),
      activeBtn: 3,
      token: '',
      workData: [],
      historyData: [],
    }
  },
  methods: {
    // *获取首页数据
    async getHomeInfo() {
      let param = new URLSearchParams()
      param.append('type', this.welcomeData.type)
      param.append('access_token', this.token)
      param.append('page', this.currentPage)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/index/index',
        data: param,
      })
      console.log('12312312312321', res)
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.waitSend = res.zpnum
      this.waitChange = res.zgnum
      this.waitLook = res.fcnum
      this.totalCount = parseInt(res.datas.count)
      this.workData = res.datas.list
      console.log('1231232', this.totalCount)
    },
    // 获取巡检记录
    async getHistoryInfo() {
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('page', this.currentPageHistory)
      param.append('timetype', this.welcomeData.timetype)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/index/index',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.totalCountHistory = parseInt(res.datas.count)
      this.historyData = res.datas.list
    },
    btnClick(val) {
      this.activeBtn = val
      this.welcomeData.timetype = val
      this.getHistoryInfo()
    },
    // tabs点击事件
    handleClick(attr) {
      if (attr.index == 0) {
        //待指派
        this.welcomeData.type = 1
        this.getHomeInfo()
      }
      if (attr.index == 1) {
        //待指派
        this.welcomeData.type = 2
        this.getHomeInfo()
      }
      if (attr.index == 2) {
        //待指派
        this.welcomeData.type = 3
        this.getHomeInfo()
      }
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getHomeInfo()
    },
    handleCurrentChangeH(val) {
      this.currentPageHistory = val
      this.getHistoryInfo()
    },
  },
  created: function () {
    this.token = localStorage.getItem('btlh_token') || ''
    this.getHomeInfo()
    this.getHistoryInfo()
  },
}
</script>
<style lang="less" scoped>
.dateCol {
  height: 290px;
  /deep/ .el-calendar__header {
    padding: 12px 10px 0;
    border-bottom: 0;
  }
  /deep/ .el-calendar__body {
    padding: 10px;
    padding-top: 5px;
  }
  /deep/ .el-calendar__title {
    font-size: 10px;
  }
  /deep/ .el-button-group > .el-button {
    font-size: 10px;
    padding: 5px;
  }

  /deep/ .el-calendar-table .el-calendar-day {
    height: initial;
  }
  /deep/ .el-calendar-table tr:first-child td {
    border: 0;
  }
  /deep/ .el-calendar-table tr td:first-child {
    border: 0;
  }
  /deep/ td {
    border: 0;
    font-size: 10px;
  }
  /deep/ th {
    border: 0;
    font-size: 10px;
  }
}
.workWaitCol {
  height: 290px;
  margin-left: 20px;
  width: 77.2% !important;
  background-color: #fff;
  padding: 12px 30px;
  /deep/ .el-tabs__item.is-active {
    color: #333;
  }
  /deep/ .el-tabs__nav-wrap::after {
    background-color: #fff;
  }
  /deep/ .el-table {
    margin-top: 0;
    font-size: 10px;
    td {
      padding: 5px 0;
    }
    td,
    th.is-leaf {
      border-bottom: 0;
    }
    th {
      background-color: #f7f7f7;
      color: #222;
      font-weight: 700;
    }
    .el-table--striped .el-table__body tr.el-table__row--striped td {
      background-color: #f7f7f7;
    }
  }
  .el-table::before {
    height: 0;
  }
  .el-pagination {
    padding: 10px 5px;
  }
  .tableDataEmpty {
    text-align: center;
    padding: 95px 0;
    color: #999;
  }
}
.cilckRow {
  margin-top: 20px;
  background-color: #fff;
  display: flex;
  padding-left: 35px;
  padding-top: 40px;
  padding-bottom: 30px;
  .clickItem {
    a {
      color: #333;
      text-decoration: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 75px;
      img {
        width: 80px;
        height: 80px;
        margin-bottom: 10px;
      }
    }
  }
}
.tableRow {
  background-color: #fff;
  margin-top: 20px;
  padding-top: 20px;
  padding-left: 10px;
  .title {
    font-size: 14px;
  }
  .el-button-group {
    margin-left: 20px;
  }
  .el-button-group > .el-button {
    width: 80px;
    padding: 10px 15px !important;
    border-radius: 0;
    border: 1px solid #f0f0f0;
    font-size: 10px;
    font-weight: normal;
    color: #333;
  }
  .el-button--primary {
    background-color: #f9f9f9;
  }
  .details {
    margin-top: 15px;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 700;
    .line {
      display: block;
      width: 4px;
      height: 18px;
      background-color: #333;
      margin-right: 5px;
    }
  }
  /deep/ .el-table {
    margin-top: 0;
    font-size: 10px;
    td {
      padding: 5px 0;
    }
    td,
    th.is-leaf {
      border-bottom: 0;
    }
    th {
      background-color: #f7f7f7;
      color: #222;
      font-weight: 700;
    }
    .el-table--striped .el-table__body tr.el-table__row--striped td {
      background-color: #f7f7f7;
    }
  }
  .el-table::before {
    height: 0;
  }
  .el-pagination {
    padding: 10px 5px;
  }
}
.acitveButton {
  background-color: #067bff !important;
  color: #fff !important;
}
.tableDataEmpty1 {
  text-align: center;
  color: #999;
  padding: 100px 0 100px;
}
</style>