<template>
  <div class="handleLog">
    <el-row class="titleRow">
      <span class="line"></span>
      <span>操作日志</span>
    </el-row>
    <el-row class="selectRow">
      <div>
        <span>开始时间</span>
        <el-date-picker
          v-model="startTime"
          type="datetime"
          placeholder="选择日期时间"
          value-format="yyyy-MM-dd HH:mm:ss"
          :picker-options="pickerOptionsStart"
          clearable
        ></el-date-picker>
      </div>
      <div>
        <span>结束时间</span>
        <el-date-picker
          v-model="endTime"
          type="datetime"
          placeholder="选择日期时间"
          value-format="yyyy-MM-dd HH:mm:ss"
          :picker-options="pickerOptionsEnd"
          clearable
        ></el-date-picker>
      </div>
      <el-button @click="selectSubmit" class="checkOutBtn">筛选</el-button>
    </el-row>
    <el-row class="tableRow">
      <el-table border :data="tableData" stripe style="width: 100%">
        <el-table-column align="center" prop="id" label="记录编号"></el-table-column>
        <el-table-column align="center" prop="uid" label="账号id"></el-table-column>
        <el-table-column align="center" prop="nickname" label="账号名称"></el-table-column>
        <el-table-column align="center" label="操作名称">
          <template slot-scope="scope">
            <div v-html="scope.row.actionName"></div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="url" label="操作URL"></el-table-column>
        <el-table-column align="center" prop="addTime" label="执行时间"></el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button @click="checkDetails(scope.row.id)" class="tableBtn">查看详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        v-if="tableData.length>0"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[3, 5, 10, 20]"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalCount"
      ></el-pagination>
    </el-row>
    <!-- 风险管控详情dia开始 -->
    <!-- :title="`隐患排查清单详情(${userName})`" -->
    <el-dialog
      :title="`隐患排查清单详情`"
      class="riskDia"
      :visible.sync="dialogDetail"
      width="80%"
      :before-close="dialogDetailClose"
    >
      <el-row class="tableRow">
        <el-form ref="form" :model="formData" label-width="120px">
          <el-form-item label="记录编号">
            <el-input disabled style="width:40%" v-model="formData.id"></el-input>
          </el-form-item>
          <el-form-item label="账号id">
            <el-input disabled style="width:40%" v-model="formData.uid"></el-input>
          </el-form-item>
          <el-form-item label="账号名称">
            <el-input disabled style="width:40%" v-model="formData.nickname"></el-input>
          </el-form-item>
          <el-form-item label="操作名称">
            <el-input disabled style="width:40%" v-model="formData.actionName"></el-input>
          </el-form-item>
          <el-form-item label="操作URL">
            <el-input disabled style="width:40%" v-model="formData.url"></el-input>
          </el-form-item>
          <el-form-item label="执行时间">
            <el-input disabled style="width:40%" v-model="formData.addTime"></el-input>
          </el-form-item>
        </el-form>
      </el-row>
    </el-dialog>
    <!-- 风险管控详情dia结束 -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      userName: '',
      dialogDetail: false,
      startTime: '',
      endTime: '',
      currentPage: 1,
      totalCount: 0,
      token: '',
      pageSize: 10,
      tableData: [],
      formData: {},
      pickerOptionsStart: {
        disabledDate: (time) => {
          if (this.endTime) {
            return (
              time.getTime() > new Date(this.endTime).getTime() ||
              time.getTime() > Date.now() - 8.64e6
            )
          } else {
            return time.getTime() > Date.now() - 8.64e6
          }
        },
      },
      pickerOptionsEnd: {
        disabledDate: (time) => {
          if (this.startTime) {
            return (
              time.getTime() < new Date(this.startTime).getTime() - 86400000 ||
              time.getTime() > Date.now() - 8.64e6
            )
          } else {
            return time.getTime() > Date.now() - 8.64e6
          }
        },
      },
    }
  },
  methods: {
    // 点击查看详情
    async checkDetails(id) {
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('id', id)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/Log/detail',
        data: param,
      })
      console.log(res)
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.formData = res.datas
      this.dialogDetail = true
    },
    // 点击筛选
    selectSubmit() {
      this.gethandleList()
    },
    // 获取操作日志列表
    async gethandleList() {
      let param = new URLSearchParams()
      param.append('page', this.currentPage)
      param.append('access_token', this.token)
      param.append('starttime', this.startTime)
      param.append('endtime', this.endTime)
      param.append('length', this.pageSize)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/log/index',
        data: param,
      })
      console.log('12312312312321', res)
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.totalCount = parseInt(res.datas.count)
      this.tableData = res.datas.list
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`)
      this.pageSize = val
      this.gethandleList()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.gethandleList()
    },
    dialogDetailClose() {
      this.dialogDetail = false
    },
  },
  created: function () {
    this.token = localStorage.getItem('btlh_token') || ''
    this.userName = localStorage.getItem('btlh_nickname') || ''
    this.gethandleList()
  },
}
</script>
<style lang="less" scoped>
.handleLog {
  padding: 20px;
  background-color: #fff;
  // 标题行
  .titleRow {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;
    .line {
      display: block;
      width: 4px;
      height: 22px;
      margin-right: 10px;
      background-color: #333;
    }
  }
  // 时间选择行
  .selectRow {
    display: flex;
    font-size: 12px;
    margin-bottom: 15px;
    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 190px;
    }
    /deep/ .el-input--prefix .el-input__inner {
      position: relative;
      font-size: 10px;
      height: 26px;
      width: 160px;
      border-radius: 0;
      margin-left: 10px;
      padding-left: 10px;
      padding-right: 10px;
    }
    /deep/ .el-icon-time {
      display: none !important;
    }
    /deep/ .el-input__icon {
      position: absolute;
      right: 15px;
      bottom: 7px;
    }
    .checkOutBtn {
      font-size: 12px;
      padding: 5px 20px;
      background-color: #447ed9;
      color: #fff;
    }
  }
  // 表格
  .tableRow {
    padding: 0 10px;
  }
  /deep/ .el-table {
    margin-top: 0;
    font-size: 12px;
    td {
      padding: 5px 0;
    }
    td,
    th.is-leaf {
      border-bottom: 1px solid #eee;
    }
    th {
      background-color: #f7f7f7;
      color: #222;
      font-weight: 700;
    }
    .el-table--striped .el-table__body tr.el-table__row--striped td {
      background-color: #f7f7f7;
    }
  }
  .el-table::before {
    display: none;
  }
  // 分页
  .el-pagination {
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
  }
}
.tableBtn {
  font-size: 12px;
  padding: 5px 10px;
  background-color: #447ed9;
  color: #fff;
}
/deep/ .el-dialog__header {
  .el-dialog__title {
    font-size: 14px;
  }
  // logo
  background: url(../../assets/images/smile.png) no-repeat;
  background-size: 20px;
  background-position: 35px 22px;
  padding-left: 65px;
}
/deep/ .el-input.is-disabled .el-input__inner {
  color: #333 !important;
  background-color: #fff !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/deep/ .el-textarea.is-disabled .el-textarea__inner {
  color: #333 !important;
  background-color: #fff !important;
  overflow: auto;
  text-overflow: ellipsis;
}
</style>